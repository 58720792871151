import { grey, blueGrey } from 'styles/colors';

export default function FactoryBrandProductLogo({darkMode, ...rest}) {
	return (
		<svg {...rest} preserveAspectRatio="xMinYMin" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140.3 140.3">
			<rect fill={darkMode ? blueGrey[50] : grey[700]} x="20.67" y="20.66" width="98.96" height="98.96" transform="translate(-29.05 70.15) rotate(-45)" />
			<path fill={grey[700]} d="M70.15,140.3L0,70.15,70.15,0l70.15,70.15-70.15,70.15ZM.35,70.15l69.8,69.8,69.8-69.8L70.15,.35,.35,70.15Z"/>
			<path fill={grey[darkMode ? 900 : 50]} d="M15.75,60.65h12.14v3.93h-7.61v3.36h6.15v3.8h-6.15v3.47h7.97v3.93H15.75v-18.49Zm14.78,0h7.66c3.93,0,6.54,2.56,6.54,6.08,0,2.25-1.45,4.32-3.67,5.15l4.79,7.27h-5.1l-3.99-6.36h-1.69v6.36h-4.56v-18.5h0Zm7.17,8.39c1.43,0,2.35-.99,2.35-2.27s-.93-2.25-2.35-2.25h-2.61v4.53h2.61Zm9.37,7.27l7.81-11.77h-7.35v-3.88h13.07v2.77l-7.79,11.85h7.68v3.88h-13.43v-2.85h0Zm32.23,2.91h-5.69v-6.89h-4.85v6.89h-5.69v-18.65h5.69v6.53h4.85v-6.53h5.69v18.65Zm-4.25-1.45h2.79v-15.76h-2.79v6.53h-7.74v-6.53h-2.79v15.76h2.79v-6.89h7.74v6.89Zm15.07,1.74c-5.54,0-9.72-4.13-9.72-9.62s4.19-9.62,9.74-9.62,9.72,4.13,9.72,9.62-4.19,9.62-9.74,9.62Zm.02-17.78c-4.81,0-8.29,3.43-8.29,8.17s3.55,8.17,8.27,8.17,8.29-3.51,8.29-8.17-3.55-8.17-8.27-8.17Zm0,13.83c-3.13,0-5.4-2.38-5.4-5.67s2.27-5.67,5.4-5.67,5.38,2.38,5.38,5.67-2.25,5.67-5.38,5.67Zm0-9.88c-2.32,0-3.95,1.74-3.95,4.22s1.63,4.22,3.95,4.22,3.93-1.74,3.93-4.22-1.62-4.22-3.93-4.22Zm35.25-5.13h-13.62v5.06h6.22l-5.59,8.41h-5.75v-13.47h-5.69v18.68h24.32v-5.06h-6.54l6.63-10.09v-3.53Zm-1.45,3.08l-7.78,11.83h7.68v2.3h-10.7s-1.81,0-1.81,0h-8.92v-15.78h2.79v13.48h7.97l7.5-11.3h-7.47v-2.16h10.72v1.63h0Z" />
		</svg>
	);
}
