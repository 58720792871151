import { useDialog } from 'react-app/components/DialogProvider';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import HeroBanner from 'components/HeroBanner';
import HeroBannerLogo from 'components/HeroBanner/HeroBannerLogo';
import HeroBannerHeadline from 'components/HeroBanner/HeroBannerHeadline';
import FacebookIcon from 'react-app/icons/FacebookCircle';
import InstagramIcon from '@mui/icons-material/Instagram';
import PinterestIcon from '@mui/icons-material/Pinterest';
import ShopIcon from '@mui/icons-material/ShoppingCart';
import InfoIcon from '@mui/icons-material/Info';
import { logo_holzfabrik_mono, factory_home_banner } from 'config/images';
import { appData } from 'config/data.cjs';

const LogoProps = {
	width: {xs: 120, tablet: 140, md: 240, desktop: 260}
};

const HeroBannerProps = {
	overlay: true,
	image: {
		...factory_home_banner
	},
	sx: {
		'&&': {
			maxHeight: {xs: 220, sm: 240, md: 220, desktop: 250},
			minHeight: {xs: 220, sm: 240, md: 220, desktop: 250}
		}
	}
};

const HeroBannerHeadlineProps = {
	sx: {
		maxWidth: 1000,
		paddingX: {lg: 24, xl: 12}
	}
};

const IconButtonProps = {
	component: 'a',
	target: '_blank',
	rel: 'noopener noreferrer',
	color: 'inherit',
	sx: {
		fontSize: {xs: '2rem', md: '3rem'}
	}
};

export default function FactoryHomePageHeroBanner(props) {
	const { openDialog } = useDialog();

	return (
		<HeroBanner {...HeroBannerProps}>
			<HeroBannerLogo ImgProps={logo_holzfabrik_mono} LogoProps={LogoProps} justifyContent="space-around" gap={{xs: 1, md: 2}}>
				<Stack gap={{xs: 1, md: 2, lg: 3}}>
					<HeroBannerHeadline
						{...HeroBannerHeadlineProps}
						noTextStroke
						gutterTop={false}
						title="Hochwertige Einzelstücke aus Holz und Metall"
						component="h1"
					/>
					<Stack direction="row" margin="auto" color="white">
						<IconButton
							{...IconButtonProps}
							title="Unser Online-Shop"
							href={appData.contact.shopFactory}
						>
							<ShopIcon fontSize="inherit"/>
						</IconButton>
						<IconButton
							{...IconButtonProps}
							title="Facebook"
							href={appData.contact.facebookFactory}
						>
							<FacebookIcon fontSize="inherit"/>
						</IconButton>
						<IconButton
							{...IconButtonProps}
							title="Instagram"
							href={appData.contact.instagramFactory}
						>
							<InstagramIcon fontSize="inherit"/>
						</IconButton>
						<IconButton
							{...IconButtonProps}
							title="Pinterest"
							href={appData.contact.pinterestFactory}
						>
							<PinterestIcon fontSize="inherit"/>
						</IconButton>
						<IconButton
							{...IconButtonProps}
							title="mehr Infos"
							onClick={openDialog}
							data-src="/data/Flyer_Holzfabrik_062024.pdf"
							data-title="Holzfabrik Erzgebirge Info-Flyer"
							href={appData.contact.pinterestFactory}
						>
							<InfoIcon fontSize="inherit"/>
						</IconButton>
					</Stack>
				</Stack>
			</HeroBannerLogo>
		</HeroBanner>
	);
}
